import 'App.scss';
import logo from 'logo.svg';
import React, { Component } from 'react';
import { calculateAlgoAnswer } from 'utilities';

class App extends Component {
    render() {
        const algoAnswer = calculateAlgoAnswer();
        const url = 'zealot.playgrounds.astige.com';
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="logo" alt="logo" />
                    <p className="words">
                        Pushes deploy to{' '}
                        <a href={`https://${url}`} rel="noopener noreferrer" target="_blank">
                            {url}
                        </a>
                    </p>
                </header>
                <div className="App-answer">
                    <h2>The algo answer:</h2>
                    <pre id="algoAnswer">{algoAnswer}</pre>
                </div>
            </div>
        );
    }
}

export default App;
